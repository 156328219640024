import axios from 'axios';
import Conn from '../../../services/Conn';
import Repository from '../../../services/Repository';
import Commons from '../../../services/Commons';

const BASE_URL = Conn.wholePath.name; // Replace with your actual backend API URL

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const sendFormData = async (formData) => {
  try {
    const response = await api.post('/items', formData); // Replace with your endpoint
    return response.data;
  } catch (error) {
    throw error;
  }
}
export const getDataNoParam = async (endpoint) => {
  const response = await api.get(endpoint, { headers: Repository.getHeaders }).catch((err) => Commons.RedirectToLogin()); // Replace with your endpoint
  return response.data;
};
export const getDataWithParam = async (endpoint,data) => {
  const response = await api.get(endpoint+'/'+data, { headers: Repository.getHeaders }).catch((err) => Commons.RedirectToLogin()); // Replace with your endpoint
  return response.data;
};


export const postNoData = async (endpoint) => {
  return axios.post(Repository.server + endpoint, { headers: Repository.getHeaders }).catch((e) => () => Commons.RedirectToLogin())
}
export const postData = async (endpoint, data) => {
  return axios.post(Conn.wholePath.name + endpoint, data, { headers: Repository.getHeaders }).catch((e) => () => Commons.RedirectToLogin())
}

export const AllWhmvetsEndpoint = '/hwmovement/searchbydate/sameday/noType/all'
export const hwmovementByDate = '/hwmovement'
export const findItemLike_ByDateLike = '/hwmovement/searchby/date/item/like/type'
export const findItemssbyname='/items/itembynamelike/'

