import axios from 'axios';
import React, { useEffect, useState } from 'react'

function Dashboard() {
    const [username, setUsername] = useState()
    const [userType, setUserType] = useState()

    const [progress, setProgress] = useState('');
    useEffect(()=>{// timer
        setUsername(localStorage.getItem('token'))
        setUserType(localStorage.getItem('catname'))


        const intervalId = setInterval(() => { // timer
            fetch('http://localhost:8080/LongRunning')
              .then((response) => response.text())
              .then((data) => {
                setProgress(data);
              })
              .catch((error) => {
                console.error('Error fetching data: ', error);
                clearInterval(intervalId); // Stop interval on error
              });
            },5000)
       
            return () => clearInterval(intervalId); 
       
    },[])





    return (
        <div className='dashboardHome sm_txt_12 md_txt_12'  >
            <span>
                Welcome To Stock Management system {progress} -
            </span>

            <div className='homeBox_anim' style={{zIndex: '13'}}>
                Box
            </div>
        </div>
    )
}

export default Dashboard
