export default class Conn {
    static server = {
        // name: '//stock.codeguru-pro.com:'
        name: '//townstock.codeguru-pro.com:'
        // name:'//nyakarambi.codeguru-pro.com:'
        // name: 'http://test_stockdjuma.megisha.com:'
        // name: 'http://localhost:'
    };
    static port = {
        /*the below hosted to Djuma Nyabugogo and djuma town, the both have the same port bcz they are on different servers*/
        val: '8098/'    
        // val: '8094/' 
    }
    static basicPath = {
        val: 'codeguru/api'
    }
    static wholePath = {
        name: Conn.server.name + Conn.port.val + Conn.basicPath.val  /*  http://localhost:8089/guru/api  */
    }
    static ReqContentType = 'application/json'
    static LoginToken = {
        'Content-Type': Conn.ReqContentType,
        'Authorization': 'Bearer '
    }
    static GetToken = {
        'Content-Type': Conn.ReqContentType,
        'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
}
